<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="automatic-line-item"
        navigation="automatic-line-items"
        :onNew="newItem"
        title="Automatic Line">
        <template slot-scope="{ item, data }">
            <v-row class="mt-0 pt-0">
                <v-col cols="6">
                    <div class="text-center">
                        <v-icon size="100" color="primary">mdi-auto-fix</v-icon>
                    </div>
                </v-col>
                <v-col cols="6">
                    <BT-Field-String
                        v-model="item.lineItemName"
                        label="Line Item Name"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Switch
                        v-model="item.isOn"
                        label="Is On"
                        :isEditing="data.isEditing || data.isNew" />
                </v-col>
            </v-row>
            
            <v-row class="my-2 px-3">
                <v-spacer />
                <BT-Sidebar-Mappings
                    isEditing
                    inline
                    :itemID="item.id"
                    label="Mappings"
                    navigation="order-invoice-mappings"
                    small />

                <BT-Sidebar-List
                    v-if="!data.isNew"
                    inline
                    linkGetURL="/get/Links?AutomaticLineItemID={id}"
                    label="Groups"
                    linkNavigation="automatic-line-item-groups"
                    manyText="groupName"
                    manyProp="automaticLineItemGroupID"
                    manyToMany
                    manyNavigation="automatic-line-item-groups"
                    :searchProps="['groupName']"
                    :singleID="item.id"
                    singleProp="automaticLineItemID"
                    small />

                <BT-Sidebar-External-Links
                    inline
                    :itemID="item.id"
                    label="Integrate"
                    navigation="automatic-line-item"
                    syncNavigation="automatic-line-item-syncing"
                    small />
            </v-row>
            
            <v-divider class="my-2" />
            <v-subheader>Calculating</v-subheader>

            <v-row>
                
                <v-col cols="6">
                    <BT-Field-Select
                        v-model="item.rule"
                        label="Rule"
                        :isEditing="data.isEditing || data.isNew"
                        itemText="text"
                        itemValue="value"
                        :items="ruleOptions" />
                    
                    <BT-Field-Number
                        v-if="isQuantityBased(item)"
                        v-model.number="item.quantity"
                        label="Quantity"
                        :isEditing="data.isEditing || data.isNew" />
                    <BT-Field-Number
                        v-else-if="isValueBased(item)"
                        v-model.number="item.quantity"
                        label="Value"
                        :isEditing="data.isEditing || data.isNew" />
                    <BT-Field-Number
                        v-else-if="isTagBased(item)"
                        v-model.number="item.quantity"
                        label="Tag Value"
                        :isEditing="data.isEditing || data.isNew" />
                    <BT-Field-Select
                        v-else-if="isProductBased(item)"
                        v-model="item.itemID"
                        itemText="productName"
                        itemValue="id"
                        label="Product"
                        navigation="products"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Checkbox
                        v-if="isProductBased(item)"
                        v-model="item.isReplacement"
                        label="Replace Product Line Item"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Tags
                        v-if="!isProductBased(item)"
                        v-model="item.linesWithTags"
                        label="Only Notice Lines With Certain Tags"
                        :isEditing="data.isEditing || data.isNew" />
                </v-col>
                <v-col cols="6">
                    <BT-Field-Number
                        v-model.number="item.calcOrder"
                        label="Calculating Order"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-String
                        v-model="item.optionGroupID"
                        label="Calculating Group"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Checkbox
                        v-model="item.isLastMinute"
                        label="Calculate Last Minute"
                        :isEditing="data.isEditing || data.isNew" />

                </v-col>
            </v-row>
            
            <v-divider class="my-2" />
            <v-subheader>Line Details</v-subheader>

            <v-row>
                <v-col cols="6">
                    <BT-Field-String
                        v-model="item.description"
                        label="Description"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Number
                        v-model.number="item.sortNumber"
                        label="Sort Number"
                        :isEditing="data.isEditing || data.isNew" />

                    <v-list-item>
                        <v-list-item-content>
                            <BT-Tax-Type 
                                v-model="item.taxType"
                                :disabled="!data.isEditing && !data.isNew" />
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="6">
                    <BT-Field-Select
                        v-if="!isProductBased(item)"
                        :items="['FixedValue', 'Percent']"
                        v-model="item.lineValueType"
                        label="Line Value Type"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Number
                        v-model.number="item.lineValue"
                        :label="`Unit Price / Value (${item.lineValueType == 'Percent' ? '% (0.10 is 10%)' : '$$$'})`"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Tags
                        v-model="item.tags"
                        label="Tags"
                        :options="['GST']"
                        :isEditing="data.isEditing || data.isNew" />
                </v-col>
            </v-row>
            
            <!-- <v-expansion-panels v-model="panel">
                <v-expansion-panel v-if="!data.isNew">
                    <v-expansion-panel-header>Xero Mappings</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <BT-Mappings
                            :isEditing="data.isEditing"
                            :itemID="item.id"
                            navigation="order-invoice-mappings" />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels> -->
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Automatic-Line-Item-Blade',
    components: {
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
        BTSidebarExternalLinks: () => import('~components/BT-Sidebar-External-Links.vue'),
        BTSidebarMappings: () => import('~components/BT-Sidebar-Mappings.vue'),
        //BTDialog: () => import('~components/BT-Dialog.vue'),
        //BTMappings: () => import('~components/BT-Mappings.vue')
    },
    data: function() {
        return {
            showEdit: false,
            currentLine: null,
            isNewLine: false,
            panel: null,
        }
    },
    props: {
        bladesData: null
    },
    computed: {
        headers() { 
            return [
            { text: 'Order', value: 'calcOrder' },
            { text: 'Name', value: 'lineItemName' },
            { text: 'Description', value: 'description' },
            { text: 'Rule', value: 'rule' },
            { text: 'Actions', value: 'actions', align: 'right' }
        ]},
        ruleOptions() {
            return [
                { text: 'Product Value Greater Than', value: 'ProductValueGreaterThan' },
                { text: 'Product Value Less Than', value: 'ProductValueLessThan' },
                { text: 'Product Value Equal To', value: 'ProductValueEqualTo' },
                { text: 'Total Value Greater Than', value: 'TotalValueGreaterThan' },
                { text: 'Total Value Less Than', value: 'TotalValueLessThan' },
                { text: 'Total Value Equal To', value: 'TotalValueEqualTo' },
                { text: 'Total Quantity Greater Than', value: 'TotalQuantityGreaterThan' },
                { text: 'Total Quantity Less Than', value: 'TotalQuantityLessThan' },
                { text: 'Total Quantity Equal To', value: 'TotalQuantityEqualTo' },
                { text: 'Product Exists', value: 'ProductExists' }
            ];
        }
    },
    methods: {
        newItem() {
            return {
                groupName: null,
                lineItems: []
            }
        },
        newLineItem() {
            this.isNewLine = true;
            this.currentLine = { };
            this.showEdit = true;
        },
        editItem(item) {
            this.isNewLine = false;
            this.currentLine = item; //this.copyDeep(item);
            this.showEdit = true;
        },
        deleteItem(item, group) {
            if (item.id) {
                group.lineItems.splice(group.lineItems.findIndex(x => x.id == item.id), 1);
            }
            else {
                group.lineItems.splice(group.lineItems.findIndex(x => x === item));
            }
        },
        closeEdit() {
            this.currentLine = null;
            this.isNewLine = false;
            this.showEdit = false;
        },
        finishEdit(group) {
            if (this.isNewLine) {
                group.lineItems.push(this.currentLine);
            }
            this.showEdit = false;            
        },
        isQuantityBased(lineItem) {
            return lineItem.rule == 'TotalQuantityGreaterThan' || lineItem.rule == 'TotalQuantityLessThan' || lineItem.rule == 'TotalQuantityEqualTo';
        },
        isTagBased(lineItem) {
            return lineItem.rule == 'LinesWithTag';
        },
        isProductBased(lineItem) {
            return lineItem.rule == 'ProductExists';
        },
        isValueBased(lineItem) {
            return lineItem.rule == 'ProductValueGreaterThan' || lineItem.rule == 'ProductValueLessThan' || lineItem.rule == 'ProductValueEqualTo' ||
                lineItem.rule == 'TotalValueGreaterThan' || lineItem.rule == 'TotalValueLessThan' || lineItem.rule == 'TotalValueEqualTo';
        },
    }
}
</script>